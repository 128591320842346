import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchTasks(ctx, payload) {
      
      return new Promise((resolve, reject) => {
        axios
          .get('https://crm-dream.ru/modules/m_dream.php?dream_agent=Y&prefix=dream2'
                , { params: {params : JSON.stringify(payload)
                            ,userData : JSON.stringify(getUserData())
                            }
                    }
                )
          // .get('/apps/todo/tasks', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addTask(ctx, taskData) {
      return new Promise((resolve, reject) => {
        axios
          // .post('https://crm-dream.ru/dra.php', { task: taskData })
          .post('https://crm-dream.ru/modules/m_dream.php?dream_agent=Y&prefix=dream2'
                , {userData: getUserData(), task: taskData }
                , {headers: {
                    'Content-Type': 'text/plain',
                  }
                })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateTask(ctx, { task }) {
      return new Promise((resolve, reject) => {
        axios
          .post('https://crm-dream.ru/modules/m_dream.php?dream_agent=Y&prefix=dream2'
                , {userData: getUserData(), task: task }
                , {headers: {
                    'Content-Type': 'text/plain',
                }
            })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/todo/tasks/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
